import "./App.css";
import React from "react";
import RotatingBanner from "./RotatingBanner";
import first from "./gifs/first.webp";
import second from "./gifs/second.webp";
import third from "./gifs/third.webp";
import Gif from "./Gif";

const HeaderAnimation = () => {
  const animations = [
    { component: <Gif src={first} />, fromMilisecond: 0, toMilisecond: 4000 },
    {
      component: <Gif src={second} />,
      fromMilisecond: 4000,
      toMilisecond: 7000,
    },
    {
      component: <Gif src={third} />,
      fromMilisecond: 7000,
      toMilisecond: 10000,
    },
    {
      component: <RotatingBanner />,
      fromMilisecond: 10000,
      toMilisecond: Infinity,
    },
  ];

  const refreshRate = 50;

  const [[initialTime, currentComponent], setCurrentTimeLapsed] =
    React.useState([new Date().getTime(), animations[0]["component"]]);

  const handleRefresh = () => {
    const currentTime = new Date().getTime();
    const milisecondsLapsed = currentTime - initialTime;
    const nextAnimation = animations.filter(
      (animation) =>
        animation.fromMilisecond < milisecondsLapsed &&
        milisecondsLapsed < animation.toMilisecond
    )[0]["component"];

    setCurrentTimeLapsed([initialTime, nextAnimation]);
  };

  const reset = () => {
    setCurrentTimeLapsed([new Date().getTime(), animations[0]["component"]]);
  };

  React.useEffect(() => {
    const timerId = setInterval(() => handleRefresh(), refreshRate);
    return () => clearInterval(timerId);
  });

  return <div onClick={reset}>{currentComponent && currentComponent}</div>;
};

export default HeaderAnimation;
