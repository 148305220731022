import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import RotatingBanner from "./RotatingBanner";
import React from "react";
import HeaderAnimation from "./HeaderAnimation";

function App() {
  return (
    <React.Fragment>
      <div className="container-header">
        <RotatingBanner />
        <HeaderAnimation />
      </div>
      <div className="content">
        <div className="container text-center">
          <div className="row">
            <header className="pb-3 mb-4">
              <title>FORKTEX</title>
            </header>
          </div>
          <footer className="pt-3 mt-4 text-muted border-top">
            <p>&copy; SIGARCH TECHNOLOGIES S.R.L. 2023</p>
            <p>email: alexandru.dochian@forktex.com</p>
            <p>phone: +40 784 935 666</p>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
