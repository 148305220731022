import "./App.css";
import React from "react";
import eye from "./images/eye.png";
import text from "./images/text.png";

const RotatingBanner = () => {
  const [[rotation_angle], setTime] = React.useState([0]);

  const tick = () => {
    setTime([rotation_angle + 0.15]);
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 30);
    return () => clearInterval(timerId);
  });

  return (
    <div>
      <img
        src={eye}
        alt="eye"
        style={{
          height: "300px",
          marginTop: "100",
          transform: `rotate(${rotation_angle}deg)`,
        }}
      />
      <img
        src={text}
        alt="text"
        style={{
          height: "300px",
          marginTop: "100",
        }}
      />
    </div>
  );
};

export default RotatingBanner;
